import React from 'react'


export default function Footer() {
	return (
		<footer className="footer">
			<span>Derechos Reservados  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`DLA Latam.`}</span> </span>
		</footer>
	)
}

