import axios from "axios";
import { AUTH_TOKEN } from "redux/constants/Auth";

const URL_BASE =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

const TOKEN_PAYLOAD_KEY = "authorization";
const ENTRY_ROUTE = "/auth/login";

class ApiService {
  _instance;

  constructor() {
    let path = "";
    if (typeof window !== "undefined") {
      path = window.location.pathname;
    }

    this._instance = axios.create({ baseURL: URL_BASE });
    this._instance.interceptors.request.use(
      function (config) {
        const jwtToken = localStorage.getItem(AUTH_TOKEN);

        if (jwtToken) {
          config.headers = {
            ...config.headers,
            [TOKEN_PAYLOAD_KEY]: `Bearer ${jwtToken}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-requested-with": "XMLHttpRequest",
          };
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this._instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401 && path !== ENTRY_ROUTE) {
          localStorage.clear();
          window.location.pathname = ENTRY_ROUTE;
        }
        return Promise.reject(error);
      }
    );
  }

  async signin(params) {
    return await this._instance.post("/login", params);
  }  
  async getUser() {
    return await this._instance.post("/getUser");
  }  

  // GRADO DE RIESGO
  async getTotalRisks(value) {
    return await this._instance.post("/getTotalRisk",value);
  }  
  async getLastDate() {
    return await this._instance.post("/getLastDate");
  }  
  async getChartRisk(value) {
    return await this._instance.post("/getChartRisk",value);
  }  
  async getTopTenRiskDesc(value) {
    return await this._instance.post("/getTopTenRiskDesc",value);
  }  
  async getTopTenRiskAsc(value) {
    return await this._instance.post("/getTopTenRiskAsc",value);
  }  
  async exportRisk(value) {
    return await this._instance.post("/exportRisk",value);
  }  
  async exportRiskHigh(value) {
    return await this._instance.post("/exportRiskHigh",value);
  }  
  async getGroupNameDetailRisk(value) {
    return await this._instance.post("/getGroupNameDetailRisk",value);
  }

  //FALLAS DE MOTOR

  async getTotalFailure(value) {
    return await this._instance.post("/getTotalFailure",value);
  }  
  async getChartFailure(value) {
    return await this._instance.post("/getChartFailure",value);
  }  
  async getTopTenFailure(value) {
    return await this._instance.post("/getTopTenFailure",value);
  }  


  //EXCESOS DE VELOCIDAD

  async getTotalHighSpeed(value) {
    return await this._instance.post("/getTotalHighSpeed",value);
  }  
  async getChartHighSpeed(value) {
    return await this._instance.post("/getChartHighSpeed",value);
  } 
  async getTopTenHighSpeed(value) {
    return await this._instance.post("/getTopTenHighSpeed",value);
  } 

  //// RALENTI

  async getIdleTotal(value) {
    return await this._instance.post("/getIdleTotal",value);
  } 
  async getChartIdle(value) {
    return await this._instance.post("/getChartIdle",value);
  } 
  async getTableGroups(value) {
    return await this._instance.post("/getTableGroups",value);
  } 
  async getTopTenIdle(value) {
    return await this._instance.post("/getTopTenIdle",value);
  } 
  async getFuelDataTable(value) {
    return await this._instance.post("/getFuelDataTable",value);
  }
  async getFuel(value) {
    return await this._instance.post("/get-fuel",value);
  } 
 
  async exportIdle(value) {
    return await this._instance.post("/exportIdle",value);
  } 


  //KM Recorridos
  async getTotalTravelled(value) {
    return await this._instance.post("/getTotalTravelled",value);
  } 
  async getChartTravelled(value) {
    return await this._instance.post("/getChartTravelled",value);
  } 
  async getTopTenTravelled(value) {
    return await this._instance.post("/getTopTenTravelled",value);
  } 
  async getDataDevices(value) {
    return await this._instance.post("/getDataDevices",value);
  } 

  //Dashboard 

  async getTotalVehicles(value) {
    return await this._instance.post("/getTotalVehicles",value);
  } 
  
  //Reports
  async reportRisk(value) {
    return await this._instance.post("/reportRisk",value);
  } 
  async getTotalRisks(value) {
    return await this._instance.post("/getTotalRisks",value);
  } 
  async getFailEngineReport(value) {
    return await this._instance.post("/getFailEngineReport",value);
  } 
  async getLowBaterryReport(value) {
    return await this._instance.post("/getLowBaterryReport",value);
  } 
  async getTotalReportIdle(value) {
    return await this._instance.post("/getTotalReportIdle",value);
  } 
  async getTotalTripsReport(value) {
    return await this._instance.post("/getTotalTripsReport",value);
  } 
  async getTotalsReport(value) {
    return await this._instance.post("/getTotalsReport",value);
  } 


  //Trends

  async getDataRisksTrends(value) {
    return await this._instance.post("/getDataRisksTrends",value);
  } 
  async getTotalYearRisk(value) {
    return await this._instance.post("/getTotalYearRisk",value);
  } 
  async getTotalYearFailure(value) {
    return await this._instance.post("/getTotalYearFailure",value);
  } 
  async getTotalYearIdle(value) {
    return await this._instance.post("/getTotalYearIdle",value);
  } 
  async getTotalYearHigh(value) {
    return await this._instance.post("/getTotalYearHigh",value);
  } 
  async getTotalYearTravelled(value) {
    return await this._instance.post("/getTotalYearTravelled",value);
  }
  async getGroupNameFilterTrends(value) {
    return await this._instance.post("/getGroupNameFilterTrends",value);
  }
  async getTemperature(value) {
    return await this._instance.post("/getTemperature",value);
  }
  async getHumidity(value) {
    return await this._instance.post("/getHumidity",value);
  }

  async getLastDateFuel() {
    return await this._instance.post("/getLastDateFuel");
  }
  async getTotalFuel(value) {
    return await this._instance.post("/get-total-fuel",value);
  }
  async getTotalPerformance(value) {
    return await this._instance.post("/get-total-performance",value);
  }
  async getRiskGrouped(value) {
    return await this._instance.post("/getRiskGrouped",value);
  }
  async getChartCornering(value) {
    return await this._instance.post("/getCornering",value);
  }

  
  //// Ultima milla

  async getFormData(value) {
    return await this._instance.post("/getFormData",value);
  }
  async getFormChart(value) {
    return await this._instance.post("/getFormChart",value);
  }

  // DLA PORTATIL

  async getNameForms() {
    return await this._instance.get("/getNameForms");
  }
  async getMonthForms() {
    return await this._instance.get("/getMonthForms");
  }

  //Events webhooks
  async getTotalEvents(value) {
    return await this._instance.post("/getTotalEvents",value);
  }
  async getChartEvents(value) {
    return await this._instance.post("/getChartEvents",value);
  }
  async getTableEvents(value) {
    return await this._instance.post("/getTableEvents",value);
  }

  /***CO2*/
  async getChartCo2(value) {
    return await this._instance.post("/get-chart-co2",value);
  }
  async getTableC02(value) {
    return await this._instance.post("/get-table-co2",value);
  }
  async getTotalCo2(value) {
    return await this._instance.post("/get-total-co2",value);
  }
  async getTotalsUM(value) {
    return await this._instance.post("/getTotalsUM",value);
  }
  async getDataTableUM(value) {
    return await this._instance.post("/getDataTableUM",value);
  }
  async getBreadcrumbData(value) {
    return await this._instance.post("/getBreadcrumbData",value);
  }
  async getHighSpeedBreadcrumbData(value) {
    return await this._instance.post("/getHighSpeedBreadcrumbData",value);
  }
}

export default new ApiService();
