import { SIDE_NAV_LIGHT } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'DLA BI-Reports';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
	"navType": "TOP",
	"sideNavTheme": SIDE_NAV_LIGHT,
	"navCollapsed": false,
	"topNavColor": "#FFF",
	"headerNavColor": "",
	"locale": "en",
	"currentTheme": "light",
	"direction": "ltr"
  };


