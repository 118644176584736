import { 
  DashboardOutlined,FundOutlined,ExclamationCircleOutlined,AppstoreOutlined,AlertOutlined,HourglassOutlined,NodeIndexOutlined,HistoryOutlined,BarChartOutlined,AreaChartOutlined,RadarChartOutlined,RiseOutlined,DotChartOutlined,SlidersOutlined,PieChartOutlined,DollarCircleOutlined,CarOutlined,AntDesignOutlined,ApiOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';



// import { ImRoad } from "react-icons/fa";

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'general_menu',
    path: `${APP_PREFIX_PATH}/report`,
    title: 'general',
    icon: DollarCircleOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'general',
        path: `${APP_PREFIX_PATH}/report`,
        title: 'general',
        icon: DollarCircleOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'roi',
        path: `${APP_PREFIX_PATH}/roi`,
        title: 'roi',
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'analysis',
        path: `${APP_PREFIX_PATH}/analysis`,
        title: 'Análisis Costos',
        icon: SlidersOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'driving',
    path: `${APP_PREFIX_PATH}/report`,
    title: 'driving',
    icon: CarOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'risks',
        path: `${APP_PREFIX_PATH}/risks`,
        title: 'risks',
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'failures',
        path: `${APP_PREFIX_PATH}/failures`,
        title: 'failures',
        icon: ExclamationCircleOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'high-speed',
        path: `${APP_PREFIX_PATH}/high-speed`,
        title: 'high-speed',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'idle',
        path: `${APP_PREFIX_PATH}/idle`,
        title: 'idle',
        icon: HourglassOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'traveled',
        path: `${APP_PREFIX_PATH}/traveled`,
        title: 'traveled',
        icon: NodeIndexOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'devices',
        path: `${APP_PREFIX_PATH}/devices`,
        title: 'devices',
        icon: RadarChartOutlined,
        breadcrumb: false,
        submenu: []
      },
      // {
      //   key: 'disconnection',
      //   path: `${APP_PREFIX_PATH}/disconnection`,
      //   title: 'disconnection',
      //   icon: ApiOutlined,
      //   breadcrumb: false,
      //   submenu: []
      // },
    ]
  },
  // {
  //   key: 'c02',
  //   path: `${APP_PREFIX_PATH}/co2`,
  //   title: 'c02',
  //   icon: PieChartOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //   ]
  // },
  {
    key: 'fuel',
    path: `${APP_PREFIX_PATH}/fuel_v2`,
    title: 'fuel',
    icon: BarChartOutlined,
    breadcrumb: false,
    submenu: []
  },
  // {
  //   key: 'tolls',
  //   path: `${APP_PREFIX_PATH}/tolls`,
  //   title: 'tolls',
  //   icon: AntDesignOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },
  {
    key: "reports",
    path: "",
    title: "reports",
    icon: FundOutlined,
    breadcrumb: false,
    submenu: [
          {
            key: 'trends',
            path: `${APP_PREFIX_PATH}/trends`,
            title: 'trends',
            icon: RiseOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'temp_hum',
            path: `${APP_PREFIX_PATH}/temp_hum`,
            title: 'temp_hum',
            icon: DotChartOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'report_ultima_milla',
            path: `${APP_PREFIX_PATH}/last_mile`,
            title: 'report_ultima_milla',
            icon: FundOutlined,
            breadcrumb: false,
            submenu: []
          },
         
    ],
  },
  // {
  //   key: 'idle-speed',
  //   path: `${APP_PREFIX_PATH}/idle-speed`,
  //   title: 'idle-speed',
  //   icon: ClockCircleOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },
  // {
  //   key: 'trips',
  //   path: `${APP_PREFIX_PATH}/trips`,
  //   title: 'trips',
  //   icon: ClockCircleOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // }
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
