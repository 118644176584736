import { createContext, useContext, useEffect, useState } from "react";
import { AUTH_TOKEN } from "redux/constants/Auth";
import ApiService from "services/ApiService";
import { Redirect, Route } from "react-router-dom";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const auth = ProviderValue();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function ProviderValue() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  let history = useHistory();



  const signin = async (user, token) => {
    localStorage.setItem(AUTH_TOKEN, token);
    setUser(user);
   
    history.push(`${APP_PREFIX_PATH}/home`);
    
  };

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = localStorage.getItem(AUTH_TOKEN);

      if (token) {
        const response = await ApiService.getUser();
        
        console.log("get user",response);
        if (response) {
          const { data } = response;
          setUser(data.data);
         
         
        }
      }
      // if(!token){
      //   if(match_register){
      //     if(!match_register.isExact){
      //         history.push(`${AUTH_PREFIX_PATH}/register`);
      //     }
      //   }else if(match_login){
      //     if(!match_login.isExact ){
      //       history.push(`${AUTH_PREFIX_PATH}/login`);
      //     }
      //   }else{
      //     history.push(`${AUTH_PREFIX_PATH}/login`);
      //   }
      // }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  return {
    user,
    loading,
    isAuthenticated: !!user,
    signin,
  };
}

export const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const user = useAuth();
  if (!user || user.loading) {
    return <div></div>;
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
        )
      }
    />
  );
};
