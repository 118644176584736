import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {  LogoutOutlined } from '@ant-design/icons';

import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useAuth } from "contexts/auth";
import { useHistory } from "react-router-dom";



export const NavProfile = () => {

  const auth = useAuth();
  let history = useHistory();

  const signOut = ()=>{

    localStorage.removeItem('auth_token');
    history.push(`${AUTH_PREFIX_PATH}/login`);
  }
  
  

  const profileImg = auth && auth.user &&  `/logos/${auth.user.company.logo}`  || "";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={95} src={profileImg} style={{width: 90,height: 90,borderRadius:0 }} />
          <div className="pl-3">
            <h4 className="mb-0">{ auth && auth.user && auth.user.name || "" }</h4>
            {/* <span className="text-muted">{auth.user.email}</span> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
         
          <Menu.Item key={1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Cerrar Sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar  src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null)(NavProfile)
